import * as React from "react"

import Layout from '../components/Layout';
import { Title, Text } from '../mui-elements/shared';
import { SEO } from '../components/SEO';

import {
  Wrapper,
  Section
} from '../style/pages/footer-pages';
import SupportForm from "../components/Forms/supportForm";

export default function Support() {
  return (
    <Layout>
      <Wrapper>
        <Section>
          <Title component="h2" variant="h2">
            Support
          </Title>
          <Text mt={1}>
            If you would like to request support please fill in the form below:
          </Text>
        </Section>
        <SupportForm />
      </Wrapper>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)
