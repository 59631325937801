import React, { useReducer } from 'react';
import axios from 'axios';
import {
  Button as MuiButton,
  TextField as MuiTextField,
  Box as MuiBox,
  Typography as MuiTypography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';

const Button = styled(MuiButton)({
  borderColor: green[800],
  transition: 'border-color .33s',
  '&:hover': {
    borderColor: green[400],
  }
});
const Input = styled(MuiTextField)({
  '& label.Mui-focused': {
    color: green[800],
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: green[800],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      transition: 'border-color .33s',
      borderColor: green[800],
    },
    '&:hover fieldset': {
      borderColor: green[400],
    },
    '&.Mui-focused fieldset': {
      borderColor: green[800],
    },
  },
});
const Form = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  transition: 'all .33s',
  maxWidth: '60%',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
})) as typeof MuiBox;
const Error = styled(MuiTypography)({
  color: red[800],
});
const Success = styled(MuiTypography)({
  color: green[800],
});

type Field = 'email' | 'client_id' | 'message' | 'status';
export type Status = 'IDLE' | 'PENDING' | 'SUCCESS' | 'ERROR';
type InitialState = {
  email: string;
  client_id: string;
  message: string;
  status: Status;
};
const INITIAL_STATE: InitialState = {
  email: '',
  client_id: '',
  message: '',
  status: 'IDLE'
};

export enum ReducerActionType {
  UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE',
  UPDATE_STATUS = 'UPDATE_STATUS',
  RESET_VALUE = 'RESET_VALUE',
}
type UpdateFieldValueAction = {
  type: ReducerActionType.UPDATE_FIELD_VALUE;
  payload: {
    field: Field;
    value: string;
  };
};
type UpdateStatusAction = {
  type: ReducerActionType.UPDATE_STATUS;
  payload: {
    value: Status;
  };
};
type ResetValueAction = {
  type: ReducerActionType.RESET_VALUE;
}
export type ReducerAction =
  | UpdateFieldValueAction
  | UpdateStatusAction
  | ResetValueAction;
const reducer = (state = INITIAL_STATE, action: ReducerAction): InitialState => {
  switch (action.type) {
    case ReducerActionType.UPDATE_FIELD_VALUE:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    case ReducerActionType.UPDATE_STATUS:
      return {
        ...state,
        status: action.payload.value
      };
    case ReducerActionType.RESET_VALUE:
      return INITIAL_STATE;
    default:
      return INITIAL_STATE;
  }
};

const SupportForm = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const handleSubmit = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    const data = {
      from: `Client ID ${state.client_id} <${state.email}>`,
      to: 'support@voltaware.com',
      subject: 'Support',
      message:
      `E-mail: ${state.email}
Client ID: ${state.client_id}
Message: ${state.message}
        `
    };

    axios.post('/.netlify/functions/sendEmail', JSON.stringify(data))
      .then(_ => {
          updateStatus('SUCCESS');
      })
      .catch(error => {
        console.log({ error });
        updateStatus('ERROR');
      });
  };

  const updateStatus = (status: Status) => {
    dispatch({
      type: ReducerActionType.UPDATE_STATUS,
      payload: {
        value: status
      }
    });
  };
  const updateFieldValue = (field: Field) => (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    dispatch({
      type: ReducerActionType.UPDATE_FIELD_VALUE,
      payload: {
        field,
        value: event.target.value
      }
    });
  };

  return (
    <>
      <Form
        component="form"
        sx={{
          '& > :not(style)': { my: 1 },
        }}
        onSubmit={handleSubmit}
      >
        <Input
          id="input-name"
          label="Client ID"
          required
          value={state.client_id}
          type="text"
          onChange={updateFieldValue('client_id')}
        />
        <Input
          id="input-email"
          label="Email address"
          required
          type="email"
          value={state.email}
          onChange={updateFieldValue('email')}
        />
        <Input
          id="input-body"
          label="Message"
          required
          multiline
          rows={5}
          value={state.message}
          onChange={updateFieldValue('message')}
        />
        {state.status === 'SUCCESS' && <Success paragraph={true}>Thank you.<br/>A member of our team will be in touch.</Success>}
        {state.status === 'ERROR' && <Error paragraph={true}>An error has occurred.<br/>Please try again later or contact us directly at support@voltaware.com</Error>}
        <Button fullWidth variant="outlined" type="submit" color="success">Submit</Button>
      </Form>
    </>
  );
};

export default SupportForm;